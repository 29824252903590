<template>
    <modal @close="$emit('close')">
        <template v-slot:title>{{ $t('note') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                        <input type="text" class="form-control" v-model="this.form.name">
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Privacy') }}</h4>
                        <select class="form-control" v-model="form.categoryType">
                            <option v-for="item in listStatus" :key="item" :value="item.value">{{ item.title }}</option>
                        </select>
                    </div>
                </b-col>
                <b-col lg="12">
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                        <flatpickr v-model="form.date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                    </div>
                </b-col>
                <!-- <div class="form-check form-check-success m-2 ">
                    <input class="form-check-input" type="checkbox" id="checkAddCalendar" v-model="checkAddCalendar">
                    <label class="form-check-label" for="checkAddCalendar">{{ $t('addToCalendar') }}</label>
                </div>
                <template v-if="checkAddCalendar == true">
                    <b-col lg="12">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('productType') }}</h4>
                            <select class="form-control" v-model="form.type">
                                <option v-for="item in listType" :key="item" :value="item.value">{{ item.title }}</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('dateEvent') }}</h4>
                            <flatpickr v-model="form.date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('timeEvent') }}</h4>
                            <flatpickr v-model="form.time" :config="configTime" class="form-control border-0 dash-filter-picker shadow" @onClose="change"></flatpickr>
                        </div>
                    </b-col>
                </template> -->
                <b-col lg="12">
                    <div class="mb-3 mt-2">
                        <h4 class="fs-15">{{ $t('description') }}</h4>
                        <textarea type="text" class="form-control" v-model="this.form.description"></textarea>
                    </div>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom >
            <div >
                <button type="button" v-if="this.perm === false" class="btn btn-primary" v-on:click="add">{{ $t('Add') }}</button>
                <button type="button" v-else class="btn btn-primary" v-on:click="edit">{{ $t('Edit') }}</button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';
import { Notes } from '@/API'

let apiServe = new Notes();

export default ({
    props: ['perm','obj'],
    components: {
        modal,
        flatpickr: Vueflatpickr
    },
    data() {
        return{
            checkAddCalendar: false,
            form: {
                name: "",
                categoryType: "",
                date: "",
                description: ""
            },
            listStatus: [
                { title: this.$t('private'), value: 'Personal' },
                { title: this.$t('Work'), value: 'Work' },
            ],
            configDate: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            },
        }
    },
    created(){
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        if(this.perm == true) {
            this.form = this.obj
        }
    },
    methods: {
        add(){
            if (!this.form.name) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
            }
            if (!this.form.categoryType) {
                //перевіряємо чи вказана категорія конф
                this.$toast.error(this.$t('SpecifyPrivacy'));
            }
            // if (!this.form.date) {
            //     //перевіряємо чи вказана дата 
            //     this.$toast.error(this.$t('specifyDate'));
            // }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
            }

            if(this.form.name && this.form.categoryType && this.form.date && this.form.description){
                apiServe.addNote(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$toast.success(this.$t('Added'));
                        this.$emit('getdata')
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " #AddNote");
                    }
                })
            }
        },
        edit(){
            apiServe.editNote(this.form.id, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('getdata')
                    this.$emit('close')
                } else {
                    //
                }
            })
        }
    }
})
</script>
