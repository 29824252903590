<template>
    <PageHeader :title="title" />
    <b-row>
        <b-col xl="3">
            <b-card>
                <div class="p-4 d-flex flex-column h-100" v-if="perms[411] || perms[2000]">
                    <div class="pb-4 border-bottom border-bottom-dashed" >
                        <button type="button" class="btn btn-danger w-100" @click="showCreated = true">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-plus-circle icon-xs me-1 icon-dual-light"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <line x1="12" y1="8" x2="12" y2="16"></line>
                                <line x1="8" y1="12" x2="16" y2="12"></line>
                            </svg>
                            {{ $t('addNotes') }}
                        </button>
                    </div>
                </div>
                <div class="mx-n4 px-4 email-menu-sidebar-scroll" >
                    <div class="mail-list mt-3" >
                        <a @click="change('all')" :class="`${objParams.categoryType == '' ? 'active' : ''} copy`"><i class="bx bx-notepad me-3 align-middle fw-medium"></i> <span class="mail-list-link fw-semibold">{{ $t('all') }}</span> <span class="badge badge-soft-success ms-auto">{{ this.rows.length }}</span></a>
                    </div>
                    <div >
                        <h5 class="fs-12 text-uppercase text-muted mt-4 fw-semibold">{{ $t('Group') }}</h5>
                        <div class="mail-list mt-1" >
                            <a @click="change('personal')" :class="`${objParams.categoryType == 'personal' ? 'active' : ''} copy`">
                                <span :class="`ri-checkbox-blank-circle-${objParams.categoryType == 'personal' ? 'fill' : 'line'} me-2 text-info`"></span>
                                <span class="mail-list-link fw-semibold" >{{ this.$t('private') }}</span> 
                                <span class="badge badge-soft-success ms-auto">{{ this.personalCount }}</span>
                            </a>
                            <a @click="change('work')" :class="`${objParams.categoryType == 'work' ? 'active' : ''} copy`">
                                <span :class="`ri-checkbox-blank-circle-${objParams.categoryType == 'work' ? 'fill' : 'line'} me-2 text-warning`"></span>
                                <span class="mail-list-link fw-semibold" >{{ this.$t('Work') }}</span>
                                <span class="badge badge-soft-success ms-auto">{{ this.workCount }}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col xl="9">
            <b-card>
                <h5 class="border-bottom fs-16 fw-semibold pb-3 mb-4">{{ $t('Notes') }}</h5>
                <b-row>
                    <div class="col-xl-6 col-md-6" v-for="item in rows" :key="item" >
                        <!-- card -->
                        <div :class="`card card-animate bg-${mutateColor(item.categoryType).color}`" >
                            <div class="card-body" >
                                <div class="d-flex align-items-center" >
                                    <div class="flex-grow-1" >
                                        <p class="text-uppercase fw-medium text-white-50 mb-0">{{ item.name }}</p>
                                    </div>
                                    <div class="flex-shrink-0" v-if="perms[412] || perms[2000]">
                                        <button @click="edit(item)" :class="`btn btn-icon text-white fs-16 mb-0`"><i class="ri-file-edit-line fs-16 align-middle"></i></button>
                                    </div>
                                </div>
                                <div class="d-flex align-items-end justify-content-between mt-4" >
                                    <div >
                                        <h4 class="fs-22 fw-semibold ff-secondary mb-4 text-white">
                                            <span class="counter-value">{{ item.description }}</span>
                                        </h4>
                                        <button v-if="perms[413] || perms[2000]" @click="showRemove(item)" class="d-none d-lg-flex d-xl-flex btn btn-icon btn-sm text-white fs-16" ><i class="ri-delete-bin-line align-bottom"></i></button>
                                    </div>
                                    <div class="avatar-sm flex-shrink-0" >
                                        <span class="avatar-title bg-soft-light rounded fs-3">
                                            <i :class="`${mutateColor(item.categoryType).icon} text-white`"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-row>
            </b-card>
        </b-col>
    </b-row>

    <createdBox 
        v-if="showCreated"
        @close="closeCreate"
        @getdata="getdata"
        :perm="this.permToEdit" 
        :obj="this.form"
    />

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove" 
    />

</template>

<script>
import PageHeader from "@/components/page-header";
import createdBox from './create'
import modalremove from '@/components/modal/modalremove'
import { storeS } from '@/store';
import { Notes } from '@/API'
import { mutateColorNote } from '@/usabilityScripts/globalMutate.js'

let apiServe = new Notes();

export default {
    components: {
        PageHeader,
        createdBox,
        modalremove
    },
    data() {
        return {
            title: this.$t('note'),
            showCreated: false,
            permToEdit: false,
            form: '',
            dialogShow: false,
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                categoryType: ''
            },
            personalCount: '',
            workCount: '',
            rows: [],
            objPages: {},
            testData: [
                {
                    id: '1',
                    title: 'Запись на пРРО',
                    desc: 'Описание по подключению , заметке',
                    label: 'personal',
                    color: 'info'
                },
                {
                    id: '2',
                    title: 'Обновить информацию на сайте',
                    desc: 'Описание по задаче , заметке',
                    label: 'work',
                    color: 'warning'
                },
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            apiServe.getNotes(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.personalCount = result.data.totalPersonal
                    this.workCount = result.data.totalWork
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        change(e){
            if(e != 'all'){
                this.objParams.categoryType = e;
            } else {
                this.objParams.categoryType = '';
            }
            this.getdata()
        },
        showRemove(e){
            this.form = e;
            this.dialogShow = true
        },
        remove(){
            apiServe.deleteNote(this.form.id).then(result => {
                if(result.status == 'done'){
                    this.dialogShow = false;
                    this.$toast.success(this.$t('Removed'));
                    this.getdata();
                }
            })
        },
        edit(e){
            this.permToEdit = true;
            this.form = e;
            this.showCreated = true
        },
        closeCreate(){
            this.permToEdit = false;
            this.showCreated = false
        },
        mutateColor(e){
            return mutateColorNote(e)
        }
    },
    computed: {
        perms(){
            return storeS.perms
        }
    }
}
</script>